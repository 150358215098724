import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import { UserDto } from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class UserApiClient extends BaseCRUDApiClient<UserDto> {
  baseUrl = this.config.apiEndpoint + '/user';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }

  associateUser(cinemaId: string, userId: string, active: boolean) {
    return firstValueFrom(
      this.http.post<UserDto>(this.baseUrl + '/cinema-association', {
        userId,
        cinemaId,
        active,
      })
    );
  }

  resetPassword(userId: string) {
    return firstValueFrom(
      this.http.put<UserDto>(
        this.baseUrl + '/' + userId + '/reset-password',
        {}
      )
    );
  }
}

import { ValueGetterParams } from '@ag-grid-community/core';
import {
  EntityListField,
  PERMISSION_ACCESS,
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@do/app-common';
import { RoleDto } from '@do/common-dto';
import { FieldType, FilterType } from '@do/common-interfaces';
import { RoleCode } from '@do/common-utils';
import { marker } from '@do/ngx-translate-extract-marker';

import { User } from './models';

export const ENTITY = marker('user');

export const PERMISSION_ACCESS_ENTITY = `${PERMISSION_ACCESS}_${ENTITY}`;
export const PERMISSION_CREATE_ENTITY = `${PERMISSION_CREATE}_${ENTITY}`;
export const PERMISSION_READ_ENTITY = `${PERMISSION_READ}_${ENTITY}`;
export const PERMISSION_UPDATE_ENTITY = `${PERMISSION_UPDATE}_${ENTITY}`;
export const PERMISSION_DELETE_ENTITY = `${PERMISSION_DELETE}_${ENTITY}`;
export const getGridColumn = (roles: RoleDto[], showCustomer = true) => {
  let gridColumns: EntityListField[] = [
    {
      elementLabel: marker('Surname'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'surname',
      sortable: true,
      sort: 'asc',
    },
    {
      elementLabel: marker('Name'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'name',
    },
    {
      elementLabel: marker('Email'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'email',
    },
    {
      elementLabel: marker('Role'),
      filterType: FilterType.enum,
      fieldType: FieldType.enum,
      field: 'role.name',
      elementValues: roles.map((r) => ({
        value: r.name,
        description: r.name,
      })),
    },
  ];

  if (showCustomer) {
    gridColumns = gridColumns.concat({
      elementLabel: marker('Customer'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'customer.name',
    });
  }

  const hierarchyFields = [
    {
      elementLabel: marker('Chain'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinemaChain.name',
    },
    {
      field: 'userCinemas',
      elementLabel: marker('Cinema'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      valueGetter: (params: ValueGetterParams<User>) =>
        params.data?.role?.code === RoleCode.Installer
          ? params.data?.userCinemas
              ?.filter((uc) => uc.active)
              ?.map((uc) => uc.cinema?.name)
              .join(', ')
          : params.data?.cinema?.name,
    },
  ];

  return gridColumns.concat(hierarchyFields);
};
